.counter {
  background-color:#f5f5f5;
  padding: 20px 0;
  border-radius: 5px;
  text-align: center;
}

.count-title {
  font-size: 40px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.count-text-sub {
  font-size: 15px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.icon {
  margin: 0 auto;
  float: none;
  display: table;
  color: #4ad1e5;
}

