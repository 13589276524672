/* Background image */
.with-image {
  text-align: center;
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: -2;
}

.overlay {
  background-color: #000;
  opacity: 0.05;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}